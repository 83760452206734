import { Drawer, Menu } from "antd";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  dashboardMenus,
  MENU_KEYS,
  nonPendingPages,
} from "../../pages/Dashboard/Dashboard.helper";
import DashboardInfo from "../../pages/Dashboard/Dashboard.info";
import { getNavPath } from "../../routes/routes.helpers";
import LpButton from "../../ui/Button/Button";
import useAccountBalance from "../../hooks/useAccountBalance";
import { logout } from "../../redux/actions/user";
import withUserData from "../../HOC/withUserData";

const LpDrawer = ({ showDrawer, onClose, isGuest = true, userData = {} }) => {
  const [open, setOpen] = useState(false);
  const { firstName } = userData;

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => setOpen(showDrawer), [showDrawer]);
  const { accountDetails, accountBalance } = useAccountBalance();

  const { electricity } = MENU_KEYS;
  const [activeKey, setActiveKey] = useState(electricity);

  const getMenuItems = useMemo(() => {
    if (activeKey) {
      return dashboardMenus(activeKey);
    }
    return [];
  }, [activeKey]);

  const handleClick = useCallback(
    (e) => {
      setActiveKey(e.key);
      onClose();
      if (nonPendingPages.includes(e.key)) {
        if (location.pathname.includes(e.key)) {
          return;
        }
        navigate(getNavPath(e.key));
        return;
      }
      navigate("/coming-soon");
    },
    [navigate, location, onClose]
  );

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  
  return (
    <Drawer
      title={
        <div>
          <ion-icon name="close-outline"></ion-icon>
          <DashboardInfo
            balance={accountBalance}
            username={firstName}
            isGuest={isGuest}
            bankName={accountDetails?.bank_name}
            accountNumber={accountDetails?.account_number}
          />
        </div>
      }
      contentWrapperStyle={{ width: "75%" }}
      placement="left"
      closable={false}
      onClose={onClose}
      open={open}
      key="left"
      className="sp-drawer"
      headerStyle={{ padding: 30 }}
    >
      <Menu
        mode="inline"
        className="dashboard__menu"
        defaultSelectedKeys={[electricity]}
      >
        {getMenuItems.map(({ key, icon }) => (
          <Menu.Item
            className="dashboard__menu_item"
            icon={icon}
            key={key}
            onClick={handleClick}
          >
            <Fragment>
              {key}
              {activeKey === key && (
                <ion-icon name="arrow-forward-outline"></ion-icon>
              )}
            </Fragment>
          </Menu.Item>
        ))}
        <div className="justify-content-center mt-lg">
          <LpButton
            label="Logout"
            customClass="layout__button"
            shape="round"
            size="sm"
            buttonType="cancel-light"
            onClick={handleLogout}
          />
        </div>
      </Menu>
    </Drawer>
  );
};
export default withUserData(LpDrawer);
