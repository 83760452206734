export const Hero = () => {
  return (
    <article>
      <p>
        You are required to read and understand these rules as they are a
        binding agreement between you and lceepay Technologies Limited, the
        providers of lceepay. Customers who are under 18 years of age should review
        these terms and conditions with a parent or guardian.
      </p>
      <p>
        By registering for a lceepay account, you agree to the rules stated in this
        document. You are also deemed to accept these rules upon first use of a
        registered phone number, or any information related to a lceepay account,
        in connection with the use of lceepay.
      </p>
    </article>
  );
};

export const AllArticles = () => {
  return (
    <div className="all-articles">
      <article id="termsArticle1">
        <h3>DEFINITIONS</h3>
        <p>The following definitions relate to these Terms and Conditions:</p>
        <p>
          • "Account" is an electronic stored value account on lceepay created for
          use by the customer. Credits, debits, and charges are applied to this
          account. The account is primarily accessed through your mobile phone
          and the online systems.
        </p>
        <p>• "ATM" means automated teller machine.</p>
        <p>
          • "Content" means all information whether textual, visual, audio or
          otherwise, appearing on or available through the services.
        </p>
        <p>• "Credit" means the movement of funds into an account.</p>
        <p>• "Debit" means the movement of funds out of an account.</p>
        <p>
          • "Linked bank account" refers to the bank account that you have
          linked to your lceepay account. This allows you to carry out transactions
          using funds from your bank account instead of using your lceepay account.
        </p>
        <p>
          • "Merchant" means a seller of goods and or services who will accept
          lceepay as payment for these goods and or services.
        </p>
        <p>
          • "Mobile Payments" describes the service or process that allows
          customers to make and receive a variety of payments using their mobile
          phone, among other channels. This service is available as part of
          lceepay.
        </p>
        <p>
          • "Mobile phone" means a GSM or CDMA device which can make and receive
          telephone calls and send and receive SMS, among other communication
          options.
        </p>
        <p>
          • "Registered phone number" refers to any phone number that has been
          registered on lceepay.
        </p>
        <p>
          • "lceepay" refers to the financial services products offered by lceepay
          Technologies Limited, with a central feature being a transactional
          account(s) that may be used in relation to a wide variety of service.
        </p>
        <p>
          • "lceepay Technologies Limited" refers to a mobile payments' platform
          company registered under the laws of the Federal Republic of Nigeria.
        </p>
        <p>
          • "Services" refer to any products and services provided to the
          customer as part of lceepay.
        </p>
        <p>
          • "SMS" or "Short Message Service" is a standard communication service
          on GSM phones which is used to exchange short text messages between
          mobile devices.
        </p>
        <p>
          • "Tier 1 customer" refers to a new customer who provides the minimum
          either in a self sign-up by submitting their names, phone number,
          email address, and BVN.
        </p>
        <p>
          • "Tier 2 customer" refers to a lceepay user who has submitted his/her
          phone number, full name, full address, and a copy of a verifiable
          Government ID card.
        </p>
        <p>
          • "Tier 3 customer" refers to a lceepay user who has provided all
          required KYC details including home address and utility bill.
        </p>
        <p>
          • "PIN" means personal identification number being the secret code you
          choose for secure use of (and access to) your lceepay account.
        </p>
        <p>
          • "Products" refer to lceepay products, including Electricity bill
          payments, airtime/data top-up, cable TV payments, and other retail
          payments services.
        </p>
      </article>
      <article id="termsArticle2">
        <h3>ELIGIBILITY</h3>
        <p>
          By accepting these Terms & Conditions or by otherwise using the
          Services or the Site, you represent that You are at least 18 years of
          age and have not been previously suspended or removed from the
          Services. You represent and warrant that you have the right,
          authority, and capacity to enter into this Agreement and to abide by
          all of the terms and conditions of this Agreement. You shall not
          impersonate any person or entity, or falsely state or otherwise
          misrepresent identity, age or affiliation with any person or entity.
        </p>
      </article>
      <article id="termsArticle3">
        <h3>CHARGES AND PAYMENT TERMS</h3>
        <p>
          • Transactions on lceepay attracts various fees which varies according to
          transaction type. We reserve the sole discretion to revise the fee
          from time to time.
        </p>
        <p>
          • When you initiate and confirm a transaction on lceepay, you agree to be
          bound by and pay for that transaction. Do not commit to a transaction
          unless you are ready to pay and have checked that all provided
          information is accurate as all completed transactions are final.
        </p>
        <p>
          • lceepay is structured to make payments convenient, so we allow you to
          make payments using a number of different funding sources e.g. your
          linked bank accounts and/or bank cards. When you provide us with a
          funding source, you also authorize:
        </p>
        <p>
          • The collection and storing of source information along with other
          related transaction information.
        </p>
        <p>
          • The crediting and debiting of your chosen source when you perform
          transactions on lceepay.
        </p>
        <p>
          • When you make a payment, you authorize us (and our designated
          payment processor) to charge the full amount and any charges related
          to that transaction to the funding source you designate for the
          transaction. If your payment results in an overdraft or other fee from
          a related party, liability for such fees or penalties will rest solely
          with you.
        </p>
        <p>
          • To prevent financial loss or possible violations of the law, lceepay
          reserves the right to use its discretion in disclosing details of any
          payments associated with you with funding source issuers, law
          enforcement agencies, or impacted third parties (including other
          users). Such disclosures will originate from an order of any trial
          court with the jurisdiction to compel such a disclosure.
        </p>
      </article>
      <article id="termsArticle4">
        <h3>SECURITY AND UNAUTHORIZED USE</h3>
        <p>
          • You are responsible for protecting your computer against computer
          viruses when you use the Internet to access our website and Internet
          banking facilities. We are not liable for any computer program or code
          that may originate from our systems, and you indemnify us against any
          claims made in this regard.
        </p>
        <p>
          • When signing up for a lceepay account, you will be prompted to create a
          unique Personal Identity Number (PIN), username, password, and provide
          answers to one or more security questions of your choosing. You are
          responsible for the safekeeping and proper use of these security data.
        </p>
        <p>
          • If at any time you believe or discover that your PIN has been stolen
          or compromised, you should contact our Customer Services immediately.
          We will place your account on hold to prevent any transactions from
          being carried out as soon as we reasonably can. You will remain
          responsible for all transactions that occur until your account is put
          on hold.
        </p>
        <p>
          • Should you dispute any purchase or withdrawal debited to your
          account, you will be required to prove that it lacked your
          authorization. Such transactions will be investigated once we receive
          an affidavit clearly stating that you had not authorized the
          transaction, supported by proof of this.
        </p>
        <p>
          • We use PIN and a selection of questions to confirm your identity
          when you call Customer Services. This method gives us your
          authorization to service your account and execute your instructions.
        </p>
      </article>
      <article id="termsArticle5">
        <h3>LIABILITY</h3>
        <h6>LIMITATION OF LIABILITY</h6>
        <p>
          TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT SHALL lceepay
          TECHNOLOGIES LIMITED BE LIABLE FOR ANY DIRECT, SPECIAL, PUNITIVE,
          INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND,
          INCLUDING WITHOUT LIMITATION, LOSS OF PROFITS, DATA, BUSINESS OR
          GOODWILL, ON ANY THEORY OF LIABILITY, WHETHER ARISING UNDER TORT
          (INCLUDING NEGLIGENCE), CONTRACT OR OTHERWISE, WHETHER OR NOT lceepay
          TECHNOLOGIES LIMITED HAS BEEN ADVISED OR IS AWARE OF THE POSSIBILITY
          OF SUCH DAMAGES. IN NO EVENT SHALL THE AGGREGATE LIABILITY OF lceepay,
          WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE), PRODUCT
          LIABILITY, STRICT LIABILITY OR OTHER THEORY, ARISING OUT OF OR
          RELATING TO THE USE OF OR INABILITY TO USE THE SITE OR RELATED TO
          THESE TERMS EXCEED ANY COMPENSATION YOU PAY, IF ANY, TO lceepay FOR
          ACCESS TO OR USE OF THE SITE.
        </p>
      </article>
      <article id="termsArticle6">
        <h3>REVIEW OF TERMS AND CONDITIONS OF SERVICE</h3>
        <p>
          lceepay may at any time modify the terms and conditions ("Terms") of the
          Service by posting an updated version on our website, the revised
          version will be effective at the time we post it. If the revised
          version includes a Substantial Change, we will provide you with a
          prior notice. You can access the latest version of the Terms at any
          given time. You should regularly \review the Terms. However, if you
          continue to use the service you agree to accept and abide by the
          modified Terms. Your use of a Site after any amendment to the Terms of
          Use shall constitute your acceptance of these terms and you also agree
          to be bound by any such changes/revisions.
        </p>
      </article>
      <article id="termsArticle7">
        <h3>NOTICES</h3>
        <p>
          Your email address and phone number supplied at sign-up will be used
          as a means of communicating information, notices and documents to you.
        </p>
      </article>
      <article id="termsArticle8">
        <h3>SUSPENSION OF ACCESS TO lceepay</h3>
        <p>
          We hereby reserve the right to suspend, restrict, or terminate your
          access to the lceepay (in whole or in part) without any notice or
          liability in the following circumstances:
        </p>
        <p>
          • Upon receipt of instructions from you to suspend or terminate your
          access to the lceepay.
        </p>
        <p>
          • Upon receipt of notification from you that your Device has been lost
          or stolen.
        </p>
        <p>
          • If we suspect the Platform is being used for fraudulent or other
          illegal activities.
        </p>
        <p>
          • If we are of the opinion that you have breached a provision
          contained in this Terms of Use.
        </p>
        <p>
          • If we are of the opinion that continued access to the Platform will
          be detrimental to our business.
        </p>
        <p>
          • If you provide any information that is untrue, inaccurate, not
          current, or incomplete, or if we have reasonable grounds to suspect
          that such information is untrue, inaccurate, not current, or
          incomplete.
        </p>
      </article>
      <article id="termsArticle9">
        <h3>UNACCEPTABLE USE</h3>
        <p>
          You shall not and shall not permit any person using your Device or
          account to:
        </p>
        <p>
          • Use this Platform in a way that causes or could cause damage or
          restrict the availability or accessibility of the Platform;
        </p>
        <p>
          • Store, copy, transmit, publish, or distribute any computer virus,
          spyware, Trojan horse, worm, keystroke logger, or other malicious
          software via this Platform;
        </p>
        <p>• Conduct any fraudulent or illegal activities via the Platform;</p>
        <p>
          • You hereby understand and agree that if a fraudulent activity is
          associated with the operation of your account, we have the right to
          apply restrictions to your account and report to the appropriate law
          enforcement agencies.
        </p>
      </article>
      <article id="termsArticle10">
        <h3>INTELLECTUAL PROPERTY</h3>
        <p>
          All material and services available on the Site, its affiliates,
          subsidiaries, employees, agents, licensors or other commercial
          partners including, but not limited to, software, all informational
          text, software documentation, design of and "look and feel," layout,
          photographs, graphics, audio, video, messages, interactive and instant
          messaging, design and functions, files, documents, images, or other
          materials, whether publicly posted or privately transmitted as well as
          all derivative works thereof (collectively, the "Materials"), are
          owned by us or other parties that have licensed their material or
          provided services to us, and maybe legally privileged.
        </p>
      </article>
    </div>
  );
};
