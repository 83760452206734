export const Hero = () => {
  return (
    <article id="article1">
      <p>
        This website www.lceepay.com, is operated by lceepay Technologies Limited
        (hereinafter referred to as “we”, “our”, “us” or “lceepay”).
      </p>

      <p>
        We are committed to the protection of all personal information that you
        may provide to us. This privacy policy (“Policy”), together with our
        terms and conditions and any other documents referred to on it, sets out
        the basis on which any personal data we collect from you, or that you
        provide to us, will be processed by us.
      </p>

      <p>
        By visiting and accessing our Platform, you are accepting and consenting
        to the terms and conditions in this Policy. If you do not agree to this
        Policy, please do not access our Platform.
      </p>

      <p>
        By using this Platform and its services, you may provide us with certain
        personal information. By using lceepay or its services, you authorize us to
        use your information in any country or state that we operate in. We
        reserve the right to use such information to improve your user
        experience and facilitate mailing and traffic, and market analytics.
      </p>

      <p>
        By accessing this Platform, specific information about the user, such as
        internet protocol (IP) addresses, site navigation, user software, and
        the surfing time, along with other similar information, will be stored
        on lceepay servers. Information about their identities, such as name,
        address, contact details, billing information, and other information
        stored on this Platform, will strictly be used only for statistical
        purposes and will not be published for general access. lceepay, however,
        assumes no responsibility for the security of this information.
      </p>
    </article>
  );
};
export const ProfileInfo = () => {
  return (
    <article id="article2">
      <h3>THE INFORMATION THAT WE COLLECT FROM YOU</h3>
      <p>
        We may receive, use, store and transfer different kinds of personal data
        about you including the following:
      </p>

      <p>
        <b>Identity Data</b>: includes [first name, last name, maiden name,
        username or similar identifier, marital status, title, date of birth,
        gender].
      </p>
      <p>
        <b>Contact Data</b>: includes [billing address, delivery address, email
        address and telephone numbers].
      </p>
      <p>
        <b>Financial Data</b>: includes [bank account and payment card details].
      </p>
      <p>
        <b>Transaction Data</b>: includes [details about your transactions].
      </p>
      <p>
        <b>Device Data</b>: includes [information on the devices with which you
        access the platform].
      </p>
      <p>
        <b>Profile Data</b>: includes [your username and password, picture,
        transaction history, your interests, preferences, feedback and survey
        responses].
      </p>
      <p>
        <b>Usage Data</b>: includes [details of your use of our services].
      </p>
      <p>
        <b>Marketing and Communications Data</b>: includes [your preferences in
        receiving marketing from us and our third parties and your communication
        preferences].
      </p>
      <p>
        <b>Location Data</b>: includes [your current location disclosed by GPS
        technology or any other location territory].
      </p>

      <p>
        We may also collect, use and share aggregated data such as statistical
        or demographic data for any purpose. Aggregated data could be derived
        from your personal data but is not considered personal data as this data
        will not directly or indirectly reveal your identity. For example, we
        may aggregate your usage data to calculate the percentage of users
        accessing a specific Platform feature.
      </p>
    </article>
  );
};

export const AllArticles = () => {
  return (
    <div className="all-articles">
      <article id="article3">
        <h3>INFORMATION FROM OTHER SOURCES</h3>
        <p>
          This is information we receive about you if you use any of the other
          Platforms we operate or the other services we provide.
        </p>

        <p>
          When you create an account with us, we may collect information,
          including non-public personal information, about you from third-party
          service providers in order to verify your identity and for fraud
          prevention, including your prior addresses and names.
        </p>
      </article>
      <article id="article4">
        <h3>COOKIES</h3>
        <p>
          lceepay employs the use of cookies. By accessing Our Website, you agree
          to use cookies in agreement with our Cookie Policy. Our interactive
          Website’s optimal functionality uses cookies to retrieve the user’s
          information for each visit. Some of our affiliate partners may also
          use cookies.
        </p>

        <p>
          You can set your browser to refuse all or some browser cookies or to
          alert you when Platforms set or access cookies. If you disable or
          refuse cookies, please note that some parts of this Platform may
          become inaccessible or not function properly.
        </p>
      </article>
      <article id="article5">
        <h3>ACCEPTABLE USE</h3>
        <p>
          You may use this Platform as permitted by these Terms and Conditions
          and may not use this Platform for any purpose other than for which
          lceepay makes the Platform and its services available.
        </p>

        <p>
          We may provide you with other items in connection with your use of
          this Platform.
        </p>

        <p>
          We hereby grant you the license to use our Platform for your personal,
          non-commercial use to retrieve, display and view the Content on a
          computer screen.
        </p>

        <p>
          The license created under these Terms is limited, non-exclusive,
          non-transferable and revocable.
        </p>

        <p>
          You agree that you will not use the Contents or Materials for any
          other purpose which may be contrary to your license to use this
          Service.
        </p>

        <p>
          Any unauthorized use by you shall terminate the permission or license
          granted by this Platform.
        </p>
      </article>
      <article id="article6">
        <h3>PROHIBITED USE</h3>
        <p>You agree not to use the Service in the following manner:</p>

        <div className="ul">
          <p>
            • To harass, abuse or threaten others or otherwise violate any
            person's legal rights;
          </p>
          <p>• To perpetrate fraud;</p>
          <p>• To create or transmit unnecessary spam to any person or URL;</p>
          <p>
            • To post, transmit or cause to be posted or transmitted, any
            communication or solicitation designed to obtain password, account,
            or private information of other users or persons;
          </p>
          <p>
            • To post copyrighted content which does not belong to you and
            without obtaining the prior consent of the author;
          </p>
          <p>
            • To use robot, spider, scraper or other automated means to access
            this Service without obtaining the prior consent of lceepay;
          </p>
          <p>
            • To engage in or create any unlawful gambling, sweepstakes, or
            scheme;
          </p>
          <p>
            • Publishing or distributing any obscene or defamatory material;
          </p>
          <p>
            • Using this Service in any way that impacts user access to the
            Platform;
          </p>
          <p>
            • To engage in advertisement or solicit any User to buy or sell
            products or services without obtaining the prior consent of lceepay;
          </p>
          <p>• Disseminating computer viruses or other software;</p>
          <p>
            • Violating any intellectual property rights of lceepay or any third
            party;
          </p>
          <p>
            • To use the Platform or any of the Services for illegal spam
            activities.
          </p>
        </div>
      </article>
      <article id="article7">
        <h3>ADDITIONALLY, YOU AGREE THAT YOU WILL NOT DO AS FOLLOWS:</h3>
        <div className="ul">
          <p>
            • Interfere or attempt to interfere with the proper working of this
            Platform; or
          </p>
          <p>
            • Bypass any measures we may use to prevent or restrict access to
            this Platform;
          </p>
          <p>
            • To interfere with or circumvent the security features of this
            Service;
          </p>
          <p>
            • To damage, disable, overburden or impair this Service or any other
            person's use of this Service.
          </p>
          <p>
            • To use this Service contrary to the applicable laws and
            regulations or in a way that causes, or may cause harm to this
            Platform, any person or business entity. We reserve the right to
            terminate your use of the Service for violating any of the
            prohibited uses.
          </p>
          <p>
            • To reverse engineer or permit the reverse engineering or
            disassemble any code or software from or on the Platform or
            Services.
          </p>
          <p>
            • Violate the security of the Platform or other Services through any
            unauthorized access, circumvention of encryption or other security
            tools, data mining or interference with any host or user or network.
          </p>
        </div>
      </article>
      <article id="article8">
        <h3>THE PURPOSE FOR WHICH WE WILL USE YOUR DATA</h3>
        <p>
          <b>The information that we collect from you</b> - We may use
          information held about you in the following ways:
        </p>

        <div className="ul">
          <p>
            • To carry out our obligations arising from any contracts entered
            into between you and us and to provide you with the information,
            products and services that you request from us;
          </p>
          <p>
            • To provide you with information about other goods and services we
            offer that are similar to those that you have already purchased or
            enquired about;
          </p>
          <p>
            • To provide you, or permit selected third parties to provide you,
            with information about goods or services we feel may interest you.
            If you are an existing customer, we will only contact you by
            electronic means (e-mail or SMS) with information about goods and
            services similar to those which were the subject of a previous sale
            or negotiations of a sale to you. If you are a new customer, and
            where we permit selected third parties to use your data, we (or
            they) will contact you by electronic means only if you have
            consented to this. If you do not want us to use your data in this
            way, or to pass your details on to third parties for marketing
            purposes, please tick the relevant box situated on the form on which
            we collect your data;
          </p>
          <p>
            • To ensure that content from our site is presented in the most
            effective manner for you and to notify you about changes to our
            service;
          </p>
          <p>
            • To administer our site and for internal operations, including
            troubleshooting, data analysis, testing, research, statistical and
            survey purposes;
          </p>
          <p>
            • To measure or understand the effectiveness of advertising we serve
            to you and others, and to deliver relevant advertising to you; and
          </p>
          <p>
            • To make suggestions and recommendations to you and other users of
            our site about goods or services that may interest you or them.
            Information we receive from other sources - We will combine this
            information with information you give to us and information we
            collect about you. We will use this information and the combined
            information for the purposes set out above (depending on the types
            of information we receive).
          </p>
        </div>
      </article>
      <article id="article9">
        <h3>DISCLOSURE OF YOUR PERSONAL INFORMATION</h3>
        <p>
          You agree that we have the right to share your personal information:
        </p>

        <div className="ul">
          <p>• With any of our affiliated companies and service providers;</p>
          <p>
            • With financial service providers, including the financial
            institutions identified in your cardholder bank agreement that
            provide banking services in connection with your account;
          </p>
          <p>
            • With another user, when you sign up for our services via a
            referral link. The user that referred you may receive information
            indicating that you have enrolled with us;
          </p>
          <p>
            • With non-financial companies, such as email service providers that
            perform marketing services on our behalf, and fraud prevention
            service providers that use the information to provide services to
            us;
          </p>
          <p>
            • With a non-affiliated third-party to access and transmit your
            personal and financial information from a relevant financial
            institution. You grant the third-party the right, power, and
            authority to access and transmit this information according to the
            terms of their privacy policy;
          </p>
          <p>
            • With selected third parties including business partners,
            suppliers, and sub-contractors for the performance of any contract
            we enter into with them or you;
          </p>
          <p>
            • With third parties to whom we may choose to sell, transfer, or
            merge parts of our business or our assets or alternatively, acquire
            all or parts of their businesses;
          </p>
          <p>
            • In response to a request for information, if we are required by,
            or we believe disclosure is in accordance with, any applicable law,
            regulation, or legal process;
          </p>
          <p>
            • With relevant law enforcement officials or other third parties,
            such as investigators or auditors, if we believe it is appropriate
            to investigate fraud.
          </p>
        </div>
      </article>
      <article id="article10">
        <h3>TRANSFER TO THIRD PARTIES</h3>
        <p>
          We may provide other users with your personal information to complete
          a transaction. Such information may include your name, account
          identity, contact details or such additional information required
          information for the transaction.
        </p>
      </article>
      <article id="article11">
        <h3>LINKS TO THIRD PARTY CONTENT</h3>
        <p>
          This Platform may contain links to Platforms or applications operated
          by third parties. Please know that we do not control any such
          third-party platforms or applications or the third-party operator.
          lceepay is not responsible for and does not endorse any third-party
          platforms or applications or their availability or content. lceepay
          accepts no responsibility for adverts contained within the Platform.
          You agree that you do so at your own risk when you purchase any goods
          and/or services from any such third party. The advertiser, and not us,
          remains responsible for such goods and/or services, and if you have
          any questions or complaints about them, you should contact the
          advertiser.
        </p>

        <p>
          We are not liable for any harm or damages related to the purchase or
          use of goods, services, resources, content, or any other transactions
          made in connection with any third-party platform. Please review the
          third-party’s policies and practices carefully and make sure you
          understand them before you engage in any transaction. Complaints,
          claims, concerns, or questions regarding third-party products should
          be directed to the third-party.
        </p>
      </article>
      <article id="article12">
        <h3>USER CONTENT</h3>
        <p>
          In these Platform Standard Terms and Conditions, “Use Content” shall
          mean any audio, video, text, images, or other material or content you
          choose to display on this Platform. Concerning user content, by
          displaying it, you grant lceepay a non-exclusive, worldwide, irrevocable,
          royalty-free, sublicensable license to use, reproduce, adapt, publish,
          translate, and distribute it on any media.
        </p>

        <p>
          User Content must be your own and must not be infringing on any third
          party’s rights. lceepay reserves the right to remove any of your content
          from this Platform at any time, without notice.
        </p>

        <p>
          lceepay is permitted to monitor your activities on the Platform and
          remove any user content considered inappropriate, offensive, contrary
          to applicable laws and regulations, or causes a breach of these Terms
          and Conditions.
        </p>

        <p>You warrant and represent that:</p>
        <div className="ol">
          <p>
            1. You are entitled to upload/input/publicize content on our
            Platform and have the necessary legal capacity, license, or consent
            to do so;
          </p>
          <p>
            2. Your content does not infringe any intellectual property right,
            including without limitation to copyright, patent, or trademark of
            any third party;
          </p>
          <p>
            3. Your content is true, accurate, current, complete, and relates to
            you and not a third party;
          </p>
          <p>
            4. Your content does not contain any libelous, defamatory,
            offensive, immoral, or otherwise illegal material which is an
            invasion of privacy; and
          </p>
          <p>
            5. The content will not be used to solicit or promote business or
            custom or present commercial activities or unlawful activity.
          </p>
        </div>

        <p>
          You must grant lceepay a non-exclusive license to use, reproduce, edit,
          and authorize others approved by us to use, reproduce, and edit any of
          your content in any form, format, or media.
        </p>
      </article>
      <article id="article13">
        <h3>ELECTRONIC COMMUNICATIONS</h3>
        <p>
          You consent to receive electronic communications and you agree that
          all agreements, notices, disclosures and other communications we
          provide to you electronically, via email and on this Platform, satisfy
          any legal requirements that communications must be in writing.
        </p>
      </article>
      <article id="article14">
        <h3>AFFILIATE MARKETING AND ADVERTISEMENT</h3>
        <p>
          We may engage in affiliate marketing and advertisement whereby we
          receive a commission on the sale of Third-Party Goods and/or Services
          through our Service. We may also accept advertising and sponsorship
          from commercial businesses or receive other forms of advertising
          compensation.
        </p>
      </article>
      <article id="article15">
        <h3>CHANGES TO PERSONAL INFORMATION</h3>
        <p>
          We deploy strict physical, electronic, and administrative security
          measures to protect your information from access by unauthorised
          persons, against unlawful processing and foreseeable hazards and
          breaches when online. We will retain your data for as long as
          necessary for the said purpose of the processing, and after that, we
          will keep your data as long as the law requires.
        </p>
      </article>
      <article id="article16">
        <h3>YOUR LEGAL RIGHTS</h3>
        <p>You have the right to:</p>
        <div className="ul">
          <p>
            • Request access to your personal data (commonly known as a "data
            subject access request"). This enables you to receive a copy of the
            personal data we hold about you and to check that we are lawfully
            processing it.
          </p>
          <p>
            • Request correction of the personal data that we hold about you.
            This enables you to have any incomplete or inaccurate data we hold
            about you corrected, though we may need to verify the accuracy of
            the new data you provide to us.
          </p>
          <p>
            • Request erasure of your personal data. This enables you to ask us
            to delete or remove personal data where there is no good reason for
            us continuing to process it. You also have the right to ask us to
            delete or remove your personal data where you have successfully
            exercised your right to object to processing (see below), where we
            may have processed your information unlawfully or where we are
            required to erase your personal data to comply with local law. Note,
            however, that we may not always be able to comply with your request
            of erasure for specific legal reasons which will be notified to you,
            if applicable, at the time of your request.
          </p>
          <p>
            • Object to processing of your personal data where we are relying on
            a legitimate interest (or those of a third party) and there is
            something about your particular situation which makes you want to
            object to processing on this ground as you feel it impacts on your
            fundamental rights and freedoms. You also have the right to object
            where we are processing your personal data for direct marketing
            purposes. In some cases, we may demonstrate that we have compelling
            legitimate grounds to process your information which override your
            rights and freedoms.
          </p>
          <p>
            • Request restriction of processing of your personal data. This
            enables you to ask us to suspend the processing of your personal
            data in the following scenarios:
          </p>
          <p>• If you want us to establish the data’s accuracy.</p>
          <p>
            • Where our use of the data is unlawful but you do not want us to
            erase it.
          </p>
          <p>
            • Where you need us to hold the data even if we no longer require it
            as you need it to establish, exercise, or defend legal claims.
          </p>
          <p>
            • You have objected to our use of your data but we need to verify
            whether we have overriding legitimate grounds to use it.
          </p>
          <p>
            • Request the transfer of your personal data to you or to a third
            party. We will provide to you, or a third party you have chosen,
            your personal data in a structured, commonly used, machine-readable
            format. Note that this right only applies to automated information
            which you initially provided consent for us to use or where we used
            the information to perform a contract with you.
          </p>
          <p>
            • Withdraw consent at any time where we are relying on consent to
            process your personal data. However, this will not affect the
            lawfulness of any processing carried out before you withdraw your
            consent. If you withdraw your consent, we may not be able to provide
            certain products or services to you. We will advise you if this is
            the case at the time you withdraw your consent.
          </p>
        </div>{" "}
      </article>
      <article id="article17">
        <h3>SALE OF GOODS/SERVICES</h3>
        <p>
          The Platform may offer Products or Services for sale. lceepay undertakes
          to give accurate information about the description of the Products and
          Services.
        </p>

        <p>
          However, lceepay does not guarantee the reliability of any information
          relating to the Products and Services. We reserve the right to refuse
          to sell the Services provided on the Platform at our sole discretion.
        </p>

        <p>
          Note that there is no refund/replacement policy as the Services
          provided are sold "As is".
        </p>
      </article>
      <article id="article18">
        <h3>LINKING AND HYPERLINKING RIGHTS</h3>
        <p>
          We reserve the right to file requests that you remove all links or any
          particular link created by you that redirect to our Platform, and you
          approve to immediately remove such links to our Platform upon request.
          We may amend the terms and conditions of these linking rights at any
          time. By continuously linking to our Platform, you agree to be bound
          to and follow the terms of this linking policy.
        </p>

        <p>
          Feel free to contact us if you find any link on our Platform that is
          offensive, and we may consider requests to remove such links. Still,
          we are not obligated to do so or respond to you directly or
          immediately.
        </p>
      </article>
      <article id="article19">
        <h3>HYPERLINKING OF OUR CONTENT</h3>
        <p>
          Organizations such as search engines, government agencies, news
          organizations, and online directories may link to our Platform without
          prior written approval. We may review other link requests from popular
          consumer and/or information specialists, charity organizations,
          internet portals, educational institutions, trade associations. Any
          interested organization must inform and contact us for further
          information regarding our linking policy. However, such linking does
          not imply endorsement, sponsorship, partnership, or approval by us of
          any kind.
        </p>

        <p>
          No use of our logo or other design intellectual property will be
          allowed for linking, except a trademark license agreement.
        </p>
      </article>
      <article id="article20">
        <h3>CHANGE TO SERVICE</h3>
        <p>
          You accept that the lceepay may vary, alter, amend or update the Content
          or Services, at any time and without your consent.
        </p>
      </article>
      <article id="article21">
        <h3>CHANGES TO THIS POLICY</h3>
        <p>
          We may periodically update this Policy without notifying you, but we
          will display a conspicuous notice on this Platform to inform you of
          any significant changes to our Policy. Please check back frequently to
          see whether there are any updates or changes to this Policy.
        </p>
      </article>
      <article id="article22">
        <h3>NO WARRANTIES</h3>
        <p>
          You agree that you use this Platform solely at your risk as lceepay does
          not warrant the accuracy of the contents in this Platform.
        </p>

        <p>
          You assume all the risk of viewing, reading, downloading the contents
          of this Platform. lceepay expressly disclaims all express and implied
          warranties such as implied warranty of merchantability as lceepay makes
          no warranties that the Platform or other Services will be accurate,
          error-free, secure or uninterrupted.
        </p>

        <p>
          You are responsible for configuring your information technology,
          computer programmes to access our Platform. You should use your own
          virus protection software.
        </p>

        <p>
          lceepay makes no warranty about the suitability, reliability,
          availability, timeliness and accuracy of the information, Contents,
          Services and other materials contained herein for any purpose. lceepay
          hereby disclaims all warranties and conditions with regard to the
          information, software, products and related graphics and materials,
          including all implied warranties or conditions of merchantability,
          fitness for a particular purpose, title and non-infringement.
        </p>

        <p>
          You also agree that the lceepay and its affiliates shall not be liable
          for any direct, indirect, punitive and all consequential damages or
          any damages whatsoever including, but not limited to damages for loss
          of use, data or profits, the failure to provide Services or for any
          information, software, Products, Services, related graphics and
          materials obtained through this Platform, or otherwise arising out of
          the use of this Platform, whether based on contract, negligence,
          strict liability, or otherwise.
        </p>
      </article>
      <article id="article23">
        <h3>SERVICE INTERRUPTIONS</h3>
        <p>
          lceepay may from time to time interrupt your access or use of this
          Platform including to perform some maintenance or emergency services
          and you agree that lceepay shall not be held liable for any damage, loss
          which may arise thereof.
        </p>
      </article>
      <article id="article24">
        <h3>SUSPENSION OR WITHDRAWAL OF PLATFORM</h3>
        <p>
          Our Platform is made available free of charge. We do not guarantee
          that our Platform, or any content on it, will always be available or
          be uninterrupted. We may suspend or withdraw or restrict the
          availability of all or any part of our Platform for business and
          operational reasons. We will try to give you reasonable notice of any
          suspension or withdrawal.
        </p>

        <p>
          You are also responsible for ensuring that all persons who access our
          Platform through your internet connection are aware of these terms of
          use and other applicable terms and conditions and that they comply
          with them.
        </p>
      </article>
      <article id="article25">
        <h3>TERMINATION/RESTRICTION OF ACCESS</h3>
        <p>
          The provisions of these Terms and Conditions shall remain in full
          force and effect while you use the lceepay or its services. Users may
          terminate their use by following the instructions for terminating user
          accounts in your account settings or by contacting us at info{" "}
          <span>sales@lceepay.com</span>
        </p>

        <p>
          We reserve the right and sole discretion to, and without notice or
          liability, deny access to and use of the Platform (including blocking
          specific IP addresses) to any user for any reason including but not
          limited to breach of any representation, warranty, or Agreement in
          these Terms or any applicable law or regulation.
        </p>

        <p>
          We also reserve the right, if, in our sole discretion, we determine
          that your use of the Platform or its services is in breach of these
          Terms and Conditions or of any applicable law or regulation, to
          terminate your use of the Platform and its services or delete your
          account and any or all of your content, without warning or prior
          notice. Suppose we terminate or suspend your account for any reason
          set out under this section. In that case, you are prohibited from
          registering and creating a new account under your name, or a false
          identity, or the expression of a third party. In addition to
          terminating or suspending your account, lceepay reserves the right to
          take appropriate legal actions, including without limitation pursuing
          civil, criminal, and injunctive redress.
        </p>

        <p>
          lceepay reserves the right to, at its sole discretion, terminate your
          access to this Platform and the related Service or any part thereof at
          any time, for any reason and without notice.
        </p>

        <p>
          lceepay shall have the right to terminate or suspend your account for
          violating these Terms.
        </p>

        <p>
          If you register with us, you may terminate the Service at any time by
          issuing a prior notice to us. Once this is done, you will no longer be
          bound by the provisions of this Terms.
        </p>
      </article>
      <article id="article26">
        <h3>GENERAL PROVISIONS</h3>
        <p>
          Language: All correspondence made under this Agreement shall be in
          English.
        </p>

        <p>
          <b>Governing Law & Jurisdiction</b>: The Terms and Conditions of this
          Platform will be governed by and construed under the laws of the
          country or state in which lceepay operates. You hereby unconditionally
          submit to the non-exclusive jurisdiction of the courts located in
          Abuja, Nigeria for the resolution of any disputes.
        </p>

        <p>
          <b>Preservation of Immunities</b>: Nothing herein shall constitute a
          limitation upon the privileges and immunities of lceepay, which are
          specifically reserved.
        </p>

        <p>
          <b>Assignment</b>: lceepay shall be permitted to assign, transfer, and
          subcontract its rights and/or obligations under this Agreement without
          any prior notification or consent required. However, you shall not be
          permitted to assign, transfer, or subcontract any rights and/or
          obligations under these Terms. Furthermore, a person who is not a
          party to these Terms and Conditions shall have no right to enforce any
          provision contained therein.
        </p>

        <p>
          <b>Entire Agreement</b>: These Terms, disclaimers, and any other
          agreement relating to the use of this Platform constitute the entire
          agreement and shall supersede any other agreement in relation to the
          use of the Platform.
        </p>

        <p>
          <b>Separate Agreements</b>: You may have other legal agreements with
          us. Those agreements are separate from these Terms. These Terms are
          not intended to alter, amend, revise or replace the terms of the other
          agreement(s).
        </p>

        <p>
          <b>Applicable law</b>: These Terms may be governed and construed in
          accordance with the Laws of the Federal Republic of Nigeria.
        </p>

        <p>
          <b>Variation</b>: lceepay reserves the right to revise these Terms at any
          time as it sees fit. By using lceepay, you are expected to review such
          Terms regularly to ensure you comprehend all the Terms and Conditions
          regarding the use of this Platform.
        </p>

        <p>
          <b>Waiver</b>: Failure to exercise any right in these Terms shall not
          operate as a waiver. The right or remedies herein provided are
          cumulative and not exclusive of any right or remedies provided by law.
        </p>

        <p>
          <b>Severability</b>: Supposed any Term or Condition is proven to be
          unenforceable or void under any applicable law. In that case, such
          shall not render the entirety of these Terms and Conditions
          unenforceable or invalid. As a result, any such provision shall be
          deleted without affecting the remaining provisions herein. The
          provisions of these Terms and Conditions that are unlawful, void, or
          unenforceable are deemed severable from these Terms and Conditions and
          do not affect any remaining provisions validity and enforceability.
        </p>
      </article>
      <article id="article27">
        <h3>INDEMNIFICATION</h3>
        <p>
          As a condition for the use of this Platform, the User agrees to
          indemnify lceepay and its affiliates to the fullest extent, from and
          against all actions, claims, liabilities, losses, damages, costs,
          demands, and expenses (including reasonable attorney’s fees) arising
          out of the user’s use of this Platform, including without limitation,
          any claim related to the breach of any of the provisions of these
          Terms and Conditions. If dissatisfied with any or all of the content
          on this Platform or any or all of its Terms and Conditions, the user
          may discontinue using this Platform.
        </p>
        <p>
          You hereby agree to indemnify lceepay, its employees, agents, and third
          parties from and against all liabilities, costs, demands, causes of
          action, damages, and expenses (including reasonable attorney's fees)
          arising out of your unlawful use of the Platform, your violation of
          any rights of a third party, and your violation of applicable laws,
          rules, or regulations.
        </p>
      </article>
      <article id="article28">
        <h3>CONTACT US</h3>
        <p>
          To resolve any complaint or clarification regarding the use of this
          Platform or its services or receive information concerning that,
          please contact us at{" "}
          <a href="https://sales@lceepay.com" target="_blank" rel="noreferrer">
            sales@lceepay.com
          </a>
        </p>
        <p>Full name of legal entity: lceepay TECHNOLOGIES LIMITED</p>
        <p>
          Email address:{" "}
          <a href="https://sales@lceepay.com" target="_blank" rel="noreferrer">
            sales@lceepay.com
          </a>
        </p>
        <p>
          Postal address: No 16 Bamsufic Estate Lokogoma District, Abuja
          Nigeria.
        </p>
        <p>Telephone number: +234 904 728 4613</p>
      </article>
    </div>
  );
};

export const AsideSection = () => {
  return (
    <div>
      <h6>Contents</h6>
      <a href="#article1">1. INTRODUCTION</a>
      <a href="#article2">2. THE INFORMATION THAT WE COLLECT FROM YOU</a>
      <a href="#article3">3. INFORMATION FROM OTHER SOURCES</a>
      <a href="#article4">4. COOKIES</a>
      <a href="#article5">5. ACCEPTABLE USE</a>
      <a href="#article6">6. PROHIBITED USE</a>
      <a href="#article7">7. AGREEMENTS</a>
      <a href="#article8">8. THE PURPOSE FOR WHICH WE WILL USE YOUR DATA</a>
      <a href="#article9">9. DISCLOSURE OF YOUR PERSONAL INFORMATION</a>
      <a href="#article10">10. TRANSFER TO THIRD PARTIES</a>
      <a href="#article11">11. LINKS TO THIRD PARTY CONTENT</a>
      <a href="#article12">12. USER CONTENT</a>
      <a href="#article13">13. ELECTRONIC COMMUNICATIONS</a>
      <a href="#article14">14. AFFILIATE MARKETING AND ADVERTISEMENT</a>
      <a href="#article15">15. CHANGES TO PERSONAL INFORMATION</a>
      <a href="#article16">16. YOUR LEGAL RIGHTS</a>
      <a href="#article17">17. SALE OF GOODS/SERVICES</a>
      <a href="#article18">18. LINKING AND HYPERLINKING RIGHTS</a>
      <a href="#article19">19. HYPERLINKING OF OUR CONTENT</a>
      <a href="#article20">20. CHANGE TO SERVICE</a>
      <a href="#article21">21. CHANGES TO THIS POLICY</a>
      <a href="#article22">22. NO WARRANTIES</a>
      <a href="#article23">23. SERVICE INTERRUPTIONS</a>
      <a href="#article24">24. SUSPENSION OR WITHDRAWAL OF PLATFORM</a>
      <a href="#article25">25. TERMINATION/RESTRICTION OF ACCESS</a>
      <a href="#article26">26. GENERAL PROVISIONS</a>
      <a href="#article27">27. INDEMNIFICATION</a>
      <a href="#article28">28. CONTACT US</a>
    </div>
  );
};
