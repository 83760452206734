import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Layout from "./Layout";
import { useNavigate } from "react-router-dom";
import SpInput from "../../sp-components/SpInput/SpInput";
import { useFormik } from "formik";
import * as yup from 'yup';
import SpButton from "../../sp-components/SpButton/SpButton";
import SpMessage from "../../sp-components/SpMessage/SpMessage";
import {  sendOtp } from './../../redux/actions/otpVerification';
import { message } from "antd";


const validationSchema = yup.object({
  first_name: yup.string()
    .required('First Name is required'),
  last_name: yup.string()
    .required('Last Name is required'),
  phone_number: yup.string()
    .min(10, "Please enter a valid phone number")
    .max(11, "Please enter a valid phone number")
    .required('Phone Number is required'),
  email: yup.string().email('Enter a valid email address')
})


function Signup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const otpSendState = useSelector(state => state.otpVerification.send);

  const {
    loading: otpSendLoading,
  } = otpSendState;

  const initialFormData = {
    otp_meta: {
      channel: "SMS",
      action: "VERIFY_PHONE_NUMBER"
    },
  };

  const [activeBreadcrumb, setActiveBreadcrumb] = useState(1);

  const handleSubmit = useCallback(values => {
    const payload = {
      ...initialFormData.otp_meta,
      recipient: values.phone_number
    };

    dispatch(sendOtp(payload)).then((response) => {
      message.success(response?.message);
      navigate("/signup/verify", {state: values});
    }).catch(error => console.log("Otp error"));

  }, []);

  const formik = useFormik({
    initialValues: initialFormData,
    onSubmit: handleSubmit,
    validationSchema,
  });


  const handleChange = useCallback((field, value) => {
    formik.setFieldValue(field, value);
    formik.setFieldTouched(field, true);
  }, [formik, dispatch]);

  const handleBlur = useCallback((field) => {
    formik.setFieldTouched(field, true);

  }, [formik]);

  const formIsValid = formik.isValid && !otpSendLoading;

  return (
    <div className="auth">
      <Layout
        title="Hi there!"
        subTitle="Fill in the following details to start using lceepay!"
        activeKey={activeBreadcrumb}
        onClick={setActiveBreadcrumb}
      >
        <form className="form" onSubmit={formik.handleSubmit}>
          <div>
            <p>Mobile Number</p>
            <SpInput
              prefix={
                <div
                  style={{
                    color: '#B1B1B1',
                    borderRight: "2px solid rgba(177, 177, 177, 1)",
                    width: 40,
                    marginRight: 4
                  }}>+234</div>
              }
              numericOnly
              value={formik.values.phone_number}
              onChange={e => handleChange("phone_number", e.target.value)}
              onBlur={() => handleBlur("phone_number")}
              placeholder="E.g 08189031845"
              className='sp-outlined'
              maxLength={11}
            />
            <SpMessage
              type='danger'
              visible={formik.touched.phone_number && formik.errors.phone_number}
            >
              {formik.errors.phone_number}
            </SpMessage>
          </div>
          <div style={{ marginTop: 20, display: "flex", columnGap: 8 }}>
            <div>
              <p>First Name</p>
              <SpInput
                value={formik.values.amount}
                onChange={e => handleChange("first_name", e.target.value)}
                onBlur={() => handleBlur("first_name")}
                className='sp-outlined'
                placeholder="Eg. John"
              />
              <SpMessage
                type='danger'
                visible={formik.touched.first_name && formik.errors.first_name}
              >
                {formik.errors.first_name}
              </SpMessage>
            </div>
            <div>
              <p>Last Name</p>
              <SpInput
                value={formik.values.last_name}
                onChange={e => handleChange("last_name", e.target.value)}
                onBlur={() => handleBlur("last_name")}
                className='sp-outlined'
                placeholder="Eg. Okafor"
              /><SpMessage
                type='danger'
                visible={formik.touched.last_name && formik.errors.last_name}
              >
                {formik.errors.last_name}
              </SpMessage>
            </div>
          </div>

          <div style={{ marginTop: 20 }}>
            <p>Email Address (optional)</p>
            <SpInput
              value={formik.values.email}
              onChange={e => handleChange("email", e.target.value)}
              onBlur={() => handleBlur("email")}
              placeholder="Eg. eddy@gmail.com"
              className='sp-outlined'
            />
            <SpMessage
              type='danger'
              visible={formik.touched.email && formik.errors.email}
            >
              {formik.errors.email}
            </SpMessage>
          </div>

          <div className="text-center" style={{ maxWidth: "400px", marginTop: 40, marginBottom: 20 }}>
            <SpButton loading={otpSendLoading} disabled={!formIsValid} htmlType="submit">Continue</SpButton>
          </div>

          <p className="text-align-center">
            Already registered? <a href="/login">Login</a>
          </p>
        </form>
      </Layout>

    </div>
  );
}

export default Signup;
