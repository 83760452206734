import React, { createContext, memo, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { RouterProvider } from "react-router-dom";
import LpModal from "./components/Modal/Modal";
import { getModalVisible } from "./redux/selectors/modal";
import router from "./routes/root";

const ReachableContext = createContext(null);
const UnreachableContext = createContext(null);

const MainApp = () => {
  const isModalVisible = useSelector(getModalVisible, shallowEqual);

  return (
    <ReachableContext.Provider value="Light">
      <LpModal visible={isModalVisible} />
      <RouterProvider router={router} />
      <UnreachableContext.Provider value="Bamboo" />
    </ReachableContext.Provider>
  );
};

export default memo(MainApp);
