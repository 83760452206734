import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "antd/dist/reset.css";
import { Col, Row } from "antd";
import { Content } from "antd/es/layout/layout";

import _map from "lodash/map";
import ServiceTile from "../../ui/ServiceTile/ServiceTile";
import { services } from "./Homepage.helper";
import { useNavigate } from "react-router";
import { fetchServiceCategories } from "../../redux/actions/service";
import SpHighlight from "../../sp-components/SpHighlight/SpHighlight";
import heroImg from "../../assets/images/hero.png";
import withUserData from "../../HOC/withUserData";
import useSpModal from "../../sp-components/SpModal/SpModal";


function Homepage({isGuest = true, isLoggedIn}) {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const {getStarted} = useSpModal();

  const { data: categories, loading: categoriesLoading, error: categoriesError } = useSelector(state => state.service.categories);

  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState(null);
  const [serviceList, setServiceList] = useState(services);


  const wordExists = useCallback((key, service) => {
    const regex = new RegExp(key, "i");
    return regex.test(service);
  }, []);

  useEffect(() => {
    dispatch(fetchServiceCategories());
  }, []);

  useEffect(() => {
    if (value) {
      setServiceList(
        serviceList.filter(({ label }) => wordExists(value, label))
      );
      return;
    }
    if (value === "") {
      setServiceList(services);
    }
  }, [value, serviceList, wordExists]);

  const handleServiceClick = useCallback(
    (item, i) => {
      const { label, url, status } = item;

      if (status.toLowerCase() !== "active") {
        return;
      }

      if (isLoggedIn) {
        navigate(url);
        return;
      }
      
      getStarted({
        redirectTo: url,
      });

    },
    [dispatch, navigate, isGuest, isLoggedIn]
  );

  return (
    <div className="homepage">
      <Row className="homepage__container">
        <Col className="homepage__container__row-one" span={12} lg={12} xs={24}>
          <Content>
            {/* <h2 className="homepage__title">All the bills you need to pay</h2> */}
            <h4 className="homepage__title">
              Pay your <SpHighlight>utility</SpHighlight> bills with ease.
            </h4>

            <div className="line_break__lg" />
            <Row gutter={[20, 20]} className="service_tile__wrapper">

              {_map(
                categories,
                ({ name: label, image_url: icon, bg_color: bgColor, slug: url, status }, i) => (
                  <ServiceTile
                    key={i}
                    label={label}
                    icon={icon}
                    url={url}
                    bgColor={bgColor}
                    onClick={() => handleServiceClick({ label, url, status }, i)}
                    opacity={status.toLowerCase() === 'active' ? null : 0.3}
                  />
                )
              )}
            </Row>
          </Content>
        </Col>
        <Col
          className="homepage__container__row-two"
          span={12}
          lg={12}
          sm={12}
          xs={24}
        >
          <Content className="homepage_content__img_wrapper">
            <img src={heroImg} className="homepage__img" alt="welcome" />
          </Content>
        </Col>
      </Row>
    </div>
  );
}

export default withUserData(Homepage);
